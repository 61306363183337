export default {
  title: "Clubeflow",
  description:
    "Clubeflow é um sistema para administração de clubes e associações, com integração com diversos módulos",
  ipServer: "https://api.clubeflow.com.br",
  ipSocket: "api.clubeflow.com.br",
  timeoutDuration: 8000,
  urlFilesUser: "https://api.clubeflow.com.br/",
  ipClient: "https://app.clubeflow.com.br",
};

/* eslint-disable @typescript-eslint/no-var-requires */
import appConfig from "@/app.config";
import axios from "axios";
import router from "@/router";
import Swal from "sweetalert2";

function getUser() {
  const user = require("../state/modules/auth");
  return user.useUser();
}

const api = axios.create({
  baseURL: appConfig.ipServer,
  timeout: 15000,
  headers: { "x-access-token": getUser().currentToken as string },
});

api.defaults.headers.common["Authorization"] = `${getUser().currentToken}`;

// Aqui consultamos pelo servidor, se há uma nova versão do frontend disponível
api.interceptors.response.use((resp) => {
  const fe_version = resp.headers["fr-version"] || "default";
  if (
    fe_version !== localStorage.getItem("fr-version") &&
    resp.config.method == "get"
  ) {
    localStorage.setItem("fr-version", fe_version);
    getUser().needsUpdate = true;
  }
  return Promise.resolve(resp);
});
export { api };

export function isLoginExpirado(error: any) {
  if (error?.status == 401 || error?.response?.status == 401) {
    localStorage.removeItem("empresa");
    localStorage.removeItem("user");
    localStorage.removeItem("jwt");
    router.push("/login?loginExpirado=true");
    setTimeout(() => {
      Swal.close();
      Swal.fire(
        "Oops!!",
        "Por favor, efetue o login novamente, o tempo de sessão expirou."
      );
    }, 500);
  }
}

/* eslint-disable @typescript-eslint/no-var-requires */
import { defineStore } from "pinia";
import Swal from "sweetalert2";
import { IRawMaterialsGroup } from "@/types/IRawMaterialsGroup";
import { getErrorMessage } from "@/helpers/errorStatement";

export interface IRawMaterialsStateGroup {
  items: IRawMaterialsGroup[];
  newGroup: {
    name: string;
    obs: string;
    errored: boolean;
  };
  editGroup: {
    id: number | null;
    errored: boolean;
    name: string;
    obs: string;
  };
}

export const useRawMaterialsGroup = defineStore("rawMaterialsGroup", {
  state: (): IRawMaterialsStateGroup =>
    ({
      items: [],
      newGroup: {
        name: "",
        obs: "",
        errored: false,
      },
      editGroup: {
        id: null,
        name: "",
        obs: "",
        errored: false,
      },
    } as IRawMaterialsStateGroup),
  getters: {},
  actions: {
    async retrieveAllItems() {
      if (this.items.length === 0) {
        await (this as any).loadAllItems();
      }
      this.sortByAZ();
    },
    async loadAllItems(): Promise<any> {
      Swal.showLoading();

      const fileAPI = require("../../requests/api");
      const { data } = await fileAPI.api.get("/raw-material-groups");
      this.items = data as IRawMaterialsGroup[];
      Swal.close();
    },
    updateItemOnStore(item: IRawMaterialsGroup) {
      const index = this.items.indexOf(this.getRawMaterialGroupById(item.id));
      this.items[index] = item;
    },
    getRawMaterialGroupById(id: number): IRawMaterialsGroup {
      const finded = this.items.find(
        (material) => material.id === id
      ) as IRawMaterialsGroup;
      if (!finded) {
        throw new Error("Não encontrado o item com esse ID " + id);
      }
      return finded;
    },
    sortByAZ() {
      this.items.sort((a: IRawMaterialsGroup, b: IRawMaterialsGroup) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    },
    async createRawMaterialGroup(): Promise<IRawMaterialsGroup> {
      this.newGroup.errored = false;
      if (this.newGroup.name.length === 0) {
        this.newGroup.errored = true;
        throw new Error("Name is not valid");
      }
      Swal.showLoading();
      try {
        const fileAPI = require("../../requests/api");
        const { data } = await fileAPI.api.post("add-raw-material-group", {
          name: this.newGroup.name,
          obs: this.newGroup.obs || "",
        });
        this.items.push(data);
        this.newGroup.name = "";
        this.newGroup.obs = "";
        this.sortByAZ();
        Swal.close();
        return data as IRawMaterialsGroup;
      } catch (error: any) {
        Swal.fire("Oops!", getErrorMessage(error), "error");
        throw new Error(error);
      }
    },
    async editRawMaterialGroup(): Promise<IRawMaterialsGroup> {
      this.editGroup.errored = false;
      if (this.editGroup.name.length === 0) {
        this.editGroup.errored = true;
        throw new Error("Name is not valid");
      }
      if (this.editGroup.id === null) {
        throw new Error("ID is not valid");
      }
      Swal.showLoading();
      try {
        const fileAPI = require("../../requests/api");
        const { data } = await fileAPI.api.put(
          `raw-material-group/${this.editGroup.id}`,
          {
            name: this.editGroup.name,
            obs: this.editGroup.obs || "",
          }
        );
        this.updateItemOnStore(data);
        this.editGroup.id = null;
        this.editGroup.name = "";
        this.editGroup.obs = "";
        this.sortByAZ();
        Swal.close();
        return data as IRawMaterialsGroup;
      } catch (error: any) {
        Swal.fire("Oops!", getErrorMessage(error), "error");
        throw new Error(error);
      }
    },
    async deleteRawMaterialGroup(id: number): Promise<IRawMaterialsGroup> {
      Swal.showLoading();
      try {
        const fileAPI = require("../../requests/api");
        const { data } = await fileAPI.api.delete(`raw-material-group/${id}`);
        this.items = this.items.filter((item) => item.id !== id);
        this.sortByAZ();
        Swal.close();
        return data as IRawMaterialsGroup;
      } catch (error: any) {
        Swal.fire("Oops!", getErrorMessage(error), "error");
        throw new Error(error);
      }
    },
  },
});

import { createWebHistory, createRouter } from "vue-router";

import routes from "./routes";
import { useUser } from "@/state/modules/auth";

const router = createRouter({
  history: createWebHistory(),
  routes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition): object {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

// Before each route evaluates...
router.beforeEach(async (routeTo, routeFrom, next) => {
  // Check if auth is required on this route
  // (including nested routes).
  const authRequired = routeTo.matched.some((route) => route.meta.authRequired);

  // If auth isn't required for the route, just continue.
  if (!authRequired) return next();
  const user = useUser();
  await user.getCurrentUser();
  // If auth is required and the user is logged in...
  if (user.isLogged()) {
    next();
  }

  // If auth is required and the user is NOT currently logged in,
  // redirect to login.
  redirectToLogin();

  // eslint-disable-next-line no-unused-vars
  // eslint-disable-next-line no-inner-declarations
  function redirectToLogin() {
    // Pass the original route to the login component
    next({ name: "login", query: { redirectFrom: routeTo.fullPath } });
  }
  const publicPages = ["/login", "/register", "/forgot-password"];
  const authpage = !publicPages.includes(routeTo.path);

  if (authpage && !user.isLogged()) {
    return next("/login");
  }
  if (!authpage && user.isLogged()) {
    return next("/");
  }

  next();
});

export default router;

import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./state/store";
import Datepicker from "@vuepic/vue-datepicker";
import Notifications from "@kyvg/vue3-notification";
import "@vuepic/vue-datepicker/dist/main.css";

import BootstrapVue3 from "bootstrap-vue-next";
import VueLazyLoad from "vue3-lazyload";
import VueApexCharts from "vue3-apexcharts";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const vClickOutside = require("click-outside-vue3");
import MoneySpinner from "v-money-spinner";
//import { registerScrollSpy } from "vue3-scroll-spy";
import { vMaska } from "maska";
import JSZip from "jszip";

import { i18n } from "./i18n";

import "sweetalert2/dist/sweetalert2.min.css";
import "@vueform/slider/themes/default.css";
import "bootstrap-vue-next/dist/bootstrap-vue-next.css";

import "../src/design/app.scss";

createApp(App)
  .use(store)
  .use(router)
  .use(BootstrapVue3)
  .use(Notifications)
  .use(MoneySpinner)
  .use(vClickOutside)
  .use(i18n)
  .use(JSZip)
  .use(createPinia())
  //.use(registerScrollSpy)
  .use(VueApexCharts)
  .use(VueLazyLoad, {})
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  .use(require("vue-chartist"))
  .directive("maska", vMaska)
  .component("Datepicker", Datepicker)
  .mount("#app");
